import React from 'react';

const WelcomeSection = (props) => {
  return (
    <div>
      <h2>WELCOME</h2>
      <p>
        Saintfield Baptist Church is a relatively young church. We recently celebrated our 30th anniversary.
        <br/>
        Our desire is to exalt the Name of our Saviour the Lord Jesus Christ.
      </p>
      <p>
        &quot;And He is the head of the body, the church: who is the beginning, the firstborn from the dead; that in all
        things He might have the pre-eminence.&quot; <span className="page-bible-reference">Colossians. 1:18</span>
      </p>
      <p>
        We desire to see Christian’s grow; whilst for non-Christians, we desire for them to come to personal saving knowlege
        of the Lord Jesus Christ. Thus main focus in our meetings is the preaching of God’s Word.
      </p>
      <p>
        &quot;Preach the word; be instant in season, out of season; reprove, rebuke, exhort with all longsuffering and
        doctrine.&quot; <span className="page-bible-reference">2 Timothy 4:2</span>
      </p>
      <p>
        Crèche facilities and Children’s church operate during our Sunday morning meeting.
        If you are visiting in the area or seeking a place to worship we heartily invite you to come along and enjoy the
        fellowship of God’s people. We assure you of a warm welcome at all times.
      </p>
    </div>
  )
}

export default WelcomeSection;
